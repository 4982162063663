import React from 'react'
import Layout from '../components/Layout'

const About = () => {
  return (
    <Layout title={'About'} textcolor={'text-slate-700'}>
      
    </Layout>
  )
}

export default About
